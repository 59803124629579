import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { useNotify } from "react-admin";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Box,
  Button,
  LinearProgress,
} from "@mui/material";

import FirebaseService from "../services/FirebaseService";
import { generateCSVExemple } from "../../util/Functions";

export default function UploadCsvFileFirebase() {
  const [csvData, setCsvData] = useState<Array<any>>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [sucess, setSucess] = useState<string | null>(null);
  const [correctFormatFile, setCorrectFormatFile] = useState<boolean>(true);

  const notify = useNotify();

  const handleFileUpload = (data: any[][], fileInfo: any) => {
    const csvDataWithoutHeader = data.slice(0);

    const newCsv: any[] = csvDataWithoutHeader.filter(
      (it: any) => it.email_address !== null
    );

    let formatCorrect = true;

    newCsv.forEach((item) => {
      if (
        !item.date_time ||
        !item.email_address ||
        !item.number ||
        !item.stock_id ||
        !item.stock_symbol
      ) {
        formatCorrect = false;
      }
    });

    if (formatCorrect) {
      setCsvData(newCsv);
      setCorrectFormatFile(true);
    } else {
      setCsvData([]);
      setCorrectFormatFile(false);
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const handleProcessToFirebase = async () => {
    setLoading(true);
    let errorOccurred = false;

    const emailsMap = csvData
      .filter((f: any) => f.email_address != null)
      .map((it) => it.email_address);

    handleCreateEvent(emailsMap);

    for (let i = 0; i < csvData.length; i++) {
      const row = csvData[i];

      if (!row.emailaddress && row.emailaddress === null) {
        continue;
      }
      try {
        FirebaseService.storeEmail(
          row.email_address,
          row.stock_id,
          row.stock_symbol,
          false,
          new Date(row.date_time).getTime()
        );
      } catch (error) {
        console.error(error);
        errorOccurred = true;
        break;
      }
    }

    if (errorOccurred) {
      notify("Error occurred while sending emails.");
    } else {
      notify(`${csvData.length} email loaded.`);
      setTimeout(() => {
        setLoading(false);
        setSucess(
          `A total of ${csvData.length} emails were sent successfully.`
        );
      }, 3000);
    }
  };

  const handleCreateEvent = (emails: string[]) => {
    const measurement_id = "G-ECCCLYFBGQ";
    const api_secret = "mUkcmVjgS_ymSladZemrQg";

    const event: any[] = emails.map((email) => ({
      name: "sign_up",
      params: { email },
    }));

    console.log("send event to GA: ", emails);

    fetch(
      `https://www.google-analytics.com/mp/collect?measurement_id=${measurement_id}&api_secret=${api_secret}`,
      {
        method: "POST",
        body: JSON.stringify({
          client_id: `divistocks`,
          events: event,
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to send GA event.");
        }
        console.log("GA event sent successfully.");
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const downloadCSVExemple = () => {
    const blob = new Blob([generateCSVExemple()], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const name = "exmple-update-fle-";
    link.setAttribute("download", `${name}.csv`);
    link.click();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <CSVReader
          cssClass="csv-reader-input"
          onFileLoaded={handleFileUpload}
          parserOptions={papaparseOptions}
          inputStyle={{
            fontSize: 30,
            width: "100%",
            whiteSpace: "nowrap",
            color: "#fffff",
            borderRadius: "4px",
          }}
        />

        <Button variant="outlined" onClick={downloadCSVExemple}>
          Download an example CSV file
        </Button>
      </Box>

      {csvData.length > 0 && (
        <Box marginTop={4}>
          <Box display="flex" justifyContent="space-between" marginBottom={1}>
            <Typography variant="h6">
              {!sucess ? (
                `CSV file uploaded successfully!!`
              ) : (
                <span
                  style={{
                    color: "green",
                  }}
                >
                  {sucess}
                </span>
              )}
            </Typography>
            <Button
              disabled={sucess !== null}
              onClick={handleProcessToFirebase}
              variant="outlined"
            >
              save and send email
            </Button>
          </Box>
          {loading && <LinearProgress sx={{ height: "8px" }} />}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Number</TableCell>
                  <TableCell>Stock Symbol</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Date - Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.number}</TableCell>
                      <TableCell>{row.stock_symbol}</TableCell>
                      <TableCell>{row.email_address}</TableCell>
                      <TableCell>{row.date_time}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100, 200, 500]}
            component="div"
            count={csvData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
      {!sucess && !correctFormatFile && (
        <Typography variant="h6">
          <span
            style={{
              color: "red",
            }}
          >
            The CSV file uploaded fail becaouse the format is incorrect!!
          </span>
        </Typography>
      )}
    </>
  );
}
