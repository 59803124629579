import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { useNotify, useRedirect } from "react-admin";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Box,
  Button,
  LinearProgress,
} from "@mui/material";
import FirebaseService from "../services/FirebaseService";
import { generateCSVExemple } from "../../util/Functions";

export default function UploadCsvFile() {
  const [csvData, setCsvData] = useState<Array<any>>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [correctFormatFile, setCorrectFormatFile] = useState<boolean>(true);

  const notify = useNotify();
  const redirect = useRedirect();

  const downloadCSVExemple = () => {
    const blob = new Blob([generateCSVExemple()], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const name = "exmple-update-fle-";
    link.setAttribute("download", `${name}.csv`);
    link.click();
  };

  const handleFileUpload = (data: any[][], fileInfo: any) => {
    const csvDataWithoutHeader = data.slice(0);

    const newCsv: any[] = csvDataWithoutHeader.filter(
      (it: any) => it.email_address !== null
    );

    let formatCorrect = true;

    newCsv.forEach((item) => {
      if (
        !item.date_time ||
        !item.email_address ||
        !item.number ||
        !item.stock_id ||
        !item.stock_symbol
      ) {
        formatCorrect = false;
      }
    });

    if (formatCorrect) {
      setCsvData(newCsv);
      setCorrectFormatFile(true);
    } else {
      setCsvData([]);
      setCorrectFormatFile(false);
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const handleProcessToFirebase = async () => {
    setLoading(true);
    let errorOccurred = false;

    for (let i = 0; i < csvData.length; i++) {
      const row = csvData[i];
      try {
        await handleSendEmail(
          row.email_address,
          row.stock_id,
          row.stock_symbol
        );
        FirebaseService.storeEmail(
          row.email_address,
          row.stock_id,
          row.stock_symbol,
          false,
          null
        );
      } catch (error) {
        console.error("error: " + error);
        errorOccurred = true;
        setLoading(false);
        break;
      }
    }

    if (errorOccurred) {
      notify("Error occurred while sending emails.");
    } else {
      notify("Saved successfully.");
      setTimeout(() => {
        setLoading(false);
        redirect("signup");
      }, 1000);
    }
  };

  const handleSendEmail = async (
    email: string,
    stockId: string,
    title: string
  ) => {
    const data = {
      email: email,
      stockId: stockId,
      title: title,
    };

    try {
      const response = await fetch("https://www.divistocks.ai/api/prediction", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Error sending email:" + email);
      }

      const responseData = await response;
      console.log(responseData);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log("csvData", csvData);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <CSVReader
          cssClass="csv-reader-input"
          onFileLoaded={handleFileUpload}
          parserOptions={papaparseOptions}
          inputStyle={{
            fontSize: 30,
            width: "100%",
            whiteSpace: "nowrap",
            color: "#fffff",
            borderRadius: "4px",
          }}
        />

        <Button variant="outlined" onClick={downloadCSVExemple}>
          Download an example CSV file
        </Button>
      </Box>

      {csvData.length > 0 && (
        <Box marginTop={4}>
          <Box display="flex" justifyContent="space-between" marginBottom={1}>
            <Typography variant="h6">
              CSV file uploaded successfully!!
            </Typography>
            <Button onClick={handleProcessToFirebase} variant="outlined">
              save and send email
            </Button>
          </Box>
          {loading && <LinearProgress sx={{ height: "8px" }} />}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Number</TableCell>
                  <TableCell>Stock ID</TableCell>
                  <TableCell>Stock Symbol</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Date - Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.number}</TableCell>
                      <TableCell>{row.stock_id}</TableCell>
                      <TableCell>{row.stock_symbol}</TableCell>
                      <TableCell>{row.email_address}</TableCell>
                      <TableCell>{row.date_time}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100, 200, 500]}
            component="div"
            count={csvData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
      {!correctFormatFile && (
        <Typography variant="h6">
          <span
            style={{
              color: "red",
            }}
          >
            The CSV file uploaded fail becaouse the format is incorrect!!
          </span>
        </Typography>
      )}
    </>
  );
}
