import { converterDateTime } from "../pages/utils/DateUtil";

export const generateCSVExemple = () => {
    const items = [
        {number: '1',stockId: 'NASDAQ:NVDA NVDA', stockTitle: 'NVDA - NVIDIA Corp', email: 'email@exemple.com', date_time: '8/25/23 6:35'},
        {number: '2',stockId: 'NASDAQ:NVDA NVDA', stockTitle: 'NVDA - NVIDIA Corp', email: 'email@exemple.com', date_time: '8/25/23 6:35'},
    ]
    const csvHeader =
      "number,stock_id,stock_symbol,email_address,date_time\n";
    const csvRows = items
      .map((element, index) => {

        const cleanedStockSymbol = element.stockTitle.replace(/,/g, " ");

        return `${index + 1},${element.stockId},${cleanedStockSymbol},${
          element.email
        },${converterDateTime(new Date())}`;
      })
      .join("\n");
    return `${csvHeader}${csvRows}`;
  };