export const converterDate = (date: Date) => {
  const formattedDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;

  return formattedDate;
};

export const converterDateTime = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDateTime: string = new Intl.DateTimeFormat(
    "en-US",
    options
  ).format(date);

  const withoutCommaAndPeriod = formattedDateTime
    .replace(",", "")
    .replace(/ [APap][Mm]$/, "");

  return withoutCommaAndPeriod;
};
