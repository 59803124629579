import * as React from "react";
import { Admin, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
  RAFirebaseOptions,
} from "react-admin-firebase";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { firebaseConfig as config } from "./FIREBASE_CONFIG";
import Popup from "./pages/popup";
import { Divicars } from "./pages/divicars";
import { DateFilterProvider } from "./contextts/DateFilterContext";
import UploadCsvFile from "./pages/csv-upload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadCsvFileInboxMailer from "./pages/csv-upload-inbox";
import UploadCsvFileFirebase from "./pages/csv-upload-inbox-firebase";

const options: RAFirebaseOptions = {
  logging: true,
  rootRef: "root_collection/some_document",
  watch: ["lead_users"],
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

const App = () => {
  return (
    <DateFilterProvider>
      <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <Resource
          noLa
          name="signup"
          options={{ label: "signup" }}
          list={Popup}
        />
        <Resource
          noLa
          name="upload-csv"
          options={{ label: "Upload File" }}
          list={UploadCsvFile}
          icon={UploadFileIcon}
        />
        <Resource
          noLa
          name="upload-csv-inbox-mailer"
          options={{ label: "Upload File Inbox Mailer" }}
          list={UploadCsvFileInboxMailer}
          icon={UploadFileIcon}
        />
        <Resource
          noLa
          name="upload-csv-firebase"
          options={{ label: "Upload File Firebase" }}
          list={UploadCsvFileFirebase}
          icon={UploadFileIcon}
        />
        <Resource
          noLa
          name="divicars"
          options={{ label: "divicars" }}
          list={Divicars}
          icon={OpenInNewIcon}
        />
      </Admin>
    </DateFilterProvider>
  );
};

export default App;
