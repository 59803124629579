import React, { useEffect, useState } from "react";
import FirebaseService from "../services/FirebaseService";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TableHead,
  TablePagination,
  Typography,
} from "@mui/material";

import { converterDateTime } from "../utils/DateUtil";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useDateFilter } from "../../contextts/DateFilterContext";
import BasicDateRangePicker from "../../components/BasicDateRangePicker";

interface DateFilter {
  startDate: number | null;
  endDate: number | null;
}

export default function Popup() {
  const [allElements, setAllElements] = useState<any[]>([]);
  const [visibleElements, setVisibleElements] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [totalProcessYes, setTotalProcess] = useState<any[]>([]);
  const [totalProcessNo, setTotalProcessNo] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("all");
  const [loading, setLoadig] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [allSeleted, setAllSeletet] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedUsers = visibleElements.slice(startIndex, endIndex);

  const [values] = useDateFilter();

  const handleChange = (event: any) => {
    setFilter(event.target.value);
  };

  const generateCSV = () => {
    console.log("selectedItems", selectedItems); // Debugging line

    const csvHeader =
      "number,stock_id,stock_symbol,email_address,date_time,Process\n";
    const csvRows = selectedItems
      .map((element, index) => {
        console.log("element", element); // Debugging line

        const cleanedStockSymbol = element.stockTitle.replace(/,/g, " ");

        return `${index + 1},${element.stockId},${cleanedStockSymbol},${
          element.email
        },${converterDateTime(new Date(element.timestamp))},${
          element.process ? "yes" : "no"
        }`;
      })
      .join("\n");
    return `${csvHeader}${csvRows}`;
  };

  const downloadCSV = () => {
    const blob = new Blob([generateCSV()], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const name = "popups-report-" + converterDateTime(new Date());
    link.setAttribute("download", `${name}.csv`);
    link.click();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDateFilter = (): DateFilter => {
    const date: DateFilter = { startDate: null, endDate: null };

    if (values.length > 1) {
      const startDate = new Date(values[0]);
      startDate.setHours(0, 0, 0, 0);
      date.startDate = startDate.getTime();

      const endDate = new Date(values[1]);
      endDate.setHours(23, 59, 59, 999);
      date.endDate = endDate.getTime();
    }

    return date;
  };

  const fetchData = async () => {
    const dateFilter: DateFilter = getDateFilter();
    console.log("dateFilter", dateFilter);
    setFirstLoad(false);
    try {
      setLoadig(true);
      const data = await FirebaseService.findAllPopups({
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
      });
      setAllElements(data);
      setVisibleElements(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadig(false);
    }
  };

  const calculateCardTotal = () => {
    const processTrue = allElements.filter((p) => p.process === true);
    const processFalse = allElements.filter((p) => p.process !== true);
    setTotalProcess(processTrue);
    setTotalProcessNo(processFalse);
  };

  const handleCheckAll = () => {
    setAllSeletet(!allSeleted);
    if (!allSeleted) {
      setSelectedItems([...visibleElements]);
    } else {
      setSelectedItems([]);
    }
  };

  const handleDeleteAll = () => {
    setLoadig(true);
    selectedItems.forEach((selectedItem) => {
      console.log("delete selected item", selectedItem);
      FirebaseService.deleteEmail(selectedItem.id);
    });

    fetchData();
    setLoadig(false);
  };

  const isSeleted = (item: any): boolean => {
    return selectedItems.some((it) => it.id === item.id);
  };

  const handleCheckItem = (item: any) => {
    const index = selectedItems.findIndex((it) => it.id === item.id);
    if (index !== -1) {
      const updatedList = [...selectedItems];
      updatedList.splice(index, 1);
      setSelectedItems(updatedList);
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  useEffect(() => {
    if (firstLoad) {
      if (values.length > 0) {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [values]);

  useEffect(() => {
    calculateCardTotal();
  }, [allElements]);

  useEffect(() => {
    if (filter === "all") {
      setVisibleElements(allElements);
    }
    if (filter === "process") {
      setVisibleElements(totalProcessYes);
    }
    if (filter === "noprocess") {
      setVisibleElements(totalProcessNo);
    }
  }, [filter]);

  const card = (title?: string, value?: number, bgColor: string = "#ffff") => (
    <Box
      display="flex"
      flexDirection="column"
      width={300}
      height={200}
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: bgColor, borderRadius: "4px" }}
    >
      <Typography variant="h2">{value}</Typography>
      <Typography variant="h6">{title}</Typography>
    </Box>
  );

  return (
    <>
      {loading && <LinearProgress sx={{ height: "8px" }} />}
      <Box gap={10} display="flex" marginBottom={5} marginTop={10}>
        {card("Total", allElements.length, "rgb(209, 233, 252)")}
        {card("Processed Yes", totalProcessYes.length, "rgb(208, 242, 255)")}
        {card("Processed No", totalProcessNo.length, "rgb(255, 231, 217)")}
      </Box>

      <Box display="flex" gap={1} marginBottom={1} justifyContent="flex-end">
        <BasicDateRangePicker height={40} />
        <Button
          variant="outlined"
          color="error"
          disabled={selectedItems.length === 0}
          onClick={handleDeleteAll}
        >
          Delete All
        </Button>
        <Button
          variant="outlined"
          disabled={selectedItems.length === 0}
          onClick={downloadCSV}
        >
          Export CSV
        </Button>
        <FormControl
          style={{ display: "none" }}
          sx={{ m: 1, minWidth: 120 }}
          size="small"
        >
          <InputLabel id="demo-select-small-label">Popup</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filter}
            label="Popup"
            onChange={handleChange}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"process"}>Process yes</MenuItem>
            <MenuItem value={"noprocess"}>Process no</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableCell align="left">
              <Checkbox onClick={() => handleCheckAll()} />
            </TableCell>
            <TableCell sx={{ width: "30%" }}>Email</TableCell>
            <TableCell>Stock Symbol</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="right">Process</TableCell>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((row, index) => (
              <TableRow key={row.email + index}>
                <TableCell align="left">
                  <Checkbox
                    checked={isSeleted(row)}
                    onClick={() => handleCheckItem(row)}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.stockTitle}
                </TableCell>
                <TableCell align="left">
                  {row.timestamp
                    ? converterDateTime(new Date(row.timestamp))
                    : ""}
                </TableCell>
                <TableCell align="right">
                  {row.process ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <ErrorIcon color="error" />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[100, 200, 500]}
          component="div"
          count={visibleElements.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
