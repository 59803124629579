import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../FIREBASE_CONFIG";

const dbCollection = "popup";

export default class FirebaseService {
  static findAllPopups = async (filterParams: {
    startDate?: number | null;
    endDate?: number | null;
  }): Promise<any[]> => {
    const { startDate, endDate } = filterParams;

    let customerQuery = query(
      collection(db, dbCollection),
      orderBy("timestamp", "desc")
    );

    if (startDate && endDate) {
      customerQuery = query(
        customerQuery,
        where("timestamp", ">=", startDate),
        where("timestamp", "<=", endDate)
      );
    }

    const documents: any[] = [];

    const querySnapshot = await getDocs(customerQuery);

    querySnapshot.forEach((doc) => {
      const user = doc.data();
      documents.push({ ...user, id: doc.id });
    });

    return documents;
  };

  static storeEmail = async (
    email: string,
    stockId: string,
    stockTitle: string,
    process: boolean,
    timesTamp: number | null
  ) => {
    try {
      await addDoc(collection(db, dbCollection), {
        email,
        stockId,
        stockTitle,
        timestamp: timesTamp ? timesTamp : new Date().getTime(),
        process,
      });
    } catch (e) {
      console.error("Error adding conversion: ", e);
    }
  };

  static deleteEmail = async (docId: string) => {
    try {
      await deleteDoc(doc(db, dbCollection, docId));
      console.log("Document successfully deleted!");
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  };

  static countTotal = async (): Promise<number> => {
    const querySnapshot = await getDocs(query(collection(db, dbCollection)));
    return querySnapshot.size;
  };
}
