
import React, { createContext, useState } from 'react'

const DateFilterContext = createContext()

export function DateFilterProvider({ children },params) {
  const [values, setValues] = useState(params || [])
  return (
    <DateFilterContext.Provider value={[values, setValues]}>
      {children}
    </DateFilterContext.Provider>
  )
}

export function useDateFilter(params) {
  return React.useContext(DateFilterContext)
}
