import * as React from "react";

import { DatePicker } from "antd";
import { useDateFilter } from "../contextts/DateFilterContext";
import moment from "moment";

import dayjs from "dayjs";

const dateFormat = "MM-DD-YYYY";

const { RangePicker } = DatePicker;

interface Props {
  height: number;
}

const BasicDateRangePicker: React.FC<Props> = ({ height }) => {
  const [value, setValue] = useDateFilter();

  const handleChange = (dates: any) => {
    if (!dates) {
      setValue([]);
      return;
    }

    setValue(dates);
  };

  const lastSaturday = moment()
    .subtract((moment().day() + 1) % 7, "days")
    .format("MM/DD/yyyy");

  const sixDaysAgo = moment(lastSaturday)
    .subtract(6, "days")
    .format("MM/DD/yyyy");

  const rangeDefaultDate = [
    dayjs(sixDaysAgo, "MM/DD/yyyy"),
    dayjs(lastSaturday, "MM/DD/yyyy"),
  ];

  React.useEffect(() => {
    setValue(rangeDefaultDate);
  }, []);

  return (
    <>
      <RangePicker
        style={{ height: `${height}px`, zIndex: 9999 }}
        format={dateFormat}
        onChange={handleChange}
        value={value}
      />
    </>
  );
};

export default BasicDateRangePicker;
